import { useEffect } from 'react';
import Layout from '../layout/layout';
import VideoPlayer from '../components/videoPlayer';
import ShiningStarBackground from '../components/ShiningStarBackground';
import IconLogo from '../components/IconLogo';
import ScrollButton from '../components/ScrollButton';
import { Parallax } from 'react-scroll-parallax';
import BannerSlider from '../components/BannerSlider';
import CircleRing from '../components/CircleRing';
import ConcessionSlide from '../components/ConcessionSlide';

const Home = () => {

    useEffect(() => {
        document.title = 'XRB Galaxy — Extended Reality And Beyon';
        window.scrollTo(0, 0);
    }, []);

    const profileCollection = [
        {
            profileName: 'STRENGTHS',
            profileBg: 'pb-1.jpg',
            profileDesc: 'Builders who build houses and cities. When there is an act of war, they are always the ones in front, taking the initiative.',
        },
        {
            profileName: 'ARTIST',
            profileBg: 'pb-2.jpg',
            profileDesc: 'Specializes in art, design, modern architecture, fashion, furniture, music, performance, food, and many more. In conclusion, its beautiness and creativity.',
        },
        {
            profileName: 'SPIRITUAL',
            profileBg: 'pb-3.jpg',
            profileDesc: 'Traditional race that was born the earliest, managed to harvest and use energy from the carotium ore.',
        },
        {
            profileName: 'FARMER',
            profileBg: 'pb-4.jpg',
            profileDesc: 'Experts in agriculture and maintain food’s fertility for the rabbit kingdom. They live on fertile ground near a source of water.',
        },
        {
            profileName: 'INTELLIGENCE',
            profileBg: 'pb-5.jpg',
            profileDesc: 'A race that specializes in investigating the origin of their own race and further developing themselves with modern science.',
        },
    ];

    const whatTodo = [
        {
            title: 'EXPLORE THE <br>VIRTUAL WORLD',
            desc: 'Bitkub Metaverse is a vast virtual world that is filled with exciting and engaging environments waiting to be discovered. From stunning landscapes to bustling cities, there is always something new to explore.',
            image: 'wh-1.jpg'
        },
        {
            title: 'CREATE<br>YOUR AVATAR',
            desc: 'Our platform allows you to create your unique avatar that reflects your personality and style. You can customize your avatar with a variety of clothing, accessories, and features to make it truly unique.',
            image: 'wh-2.jpg'
        },
        {
            title: 'COLLECT AND TRADE<br>DIGITAL ASSETS',
            desc: 'Bitkub Metaverse enables you to collect and trade various digital assets, including virtual lands, buildings, farms, and concessions. You can also buy and sell unique in-game items to other players, making it a great platform for collectors and traders.',
            image: 'wh-3.jpg'
        },
        {
            title: 'MINI-GAMES<br>AND EVENTS',
            desc: 'Our platform hosts various mini-games and events where you can compete with other players for rewards and prizes. Our mini-games are designed to be fun and challenging.',
            image: 'wh-4.jpg'
        },
        {
            title: 'ENGAGE WITH<br>THE COMMUNITY',
            desc: "Bitkub Metaverse is not just a game, it's a vibrant and inclusive community. You can chat and interact with other players from around the world and participate in community events and activities.",
            image: 'wh-5.jpg'
        },
    ];

    const marqueeItems = [
        'xrb_bdConcession_The Pocket Mart.png',
        'xrb_bdConcession_The Tropical Temptations.png',
        'xrb_bitkubCity_Nature_s Nectar.png',
        'xrb_bitkubCity_Revitalizing Water.png',
        'xrb_bitkubCity_The Art of Leather.png',
        'xrb_bitkubCity_The Gemstone Giggle.png',
        'xrb_mutelulu_antiqueShop_The Retro Relics.png',
        'xrb_mutelulu_clinic_XS_The Health Hive.png',
    ];

    return (
        <Layout>
            <h1 className='sr-only'>Bitkub Metaverse</h1>

            <Parallax speed={0} translateY={[0, -20]}
                style={{
                    position: 'fixed',
                    top: '0',
                    width: '100%',
                    height: '130vh',
                    backgroundImage: 'url(/assets/images/bg-1.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    zIndex: '0',
                }}
            >
                <ShiningStarBackground numberOfStars={300} />
            </Parallax>

            <div className='banner-video-section z-20 relative' style={{
                height: 'calc(100vh - 154px)',

            }}>

                <div className='w-full magic-height absolute top-0'>
                    <VideoPlayer src={`/assets/videos/WRB_webBanner.edit.v001.mp4`} />
                </div>

                <div className='w-full magic-height absolute top-0 flex flex-wrap justify-center items-center'>
                    <div className='w-full text-center'>
                        <div style={{ height: '60vh' }} className='flex justify-center items-center w-10/12 lg:w-5/12 mx-auto'>
                            <IconLogo />
                        </div>
                        {/*<a href="https://xrb-land.megaland.io/" target='_blank' rel="noreferrer">
                            <div className='transition-all bg-color4 hover:bg-color3 inline-block px-9 py-4 rounded-xl b-shadow-inner'>
                                <h4 className='font-bold text-3xl mb-1'>RESERVE LAND</h4>
                                <p className='text-xs'>Whitelist: Mon 8th May at 6pm<br />Public: Tue 9th May at 6pm</p>
                            </div>
                        </a>*/}
                    </div>

                </div>
            </div>



            <div
                id="what-is"
                className=' z-10 px-5 lg:px-16  py-16 relative overflow-hidden  min-h-screen bg-black/10'
                style={{ marginTop: '40px' }}
            >
                <div className='w-full text-center '>
                    <ScrollButton targetId="what-is" />
                </div>

                <div className='grid lg:grid-cols-12 gap-8 items-center pb-10'>
                    <div className='col-span-6 lg:pl-16'>
                        <h2 className='text-xl lg:text-4xl tracking-wide mt-4 mb-2 font-sans'>WHAT IS</h2>
                        <h2 className='text-2xl lg:text-4xl 2xl:text-5xl font-extrabold mb-4 tracking-wide'>XRB GALAXY?</h2>
                        <p className='mb-16 text-base 2xl:text-lg'>XRB Galaxy is a virtual universe that takes you beyond the limits of your imagination! With an infinite expanse of planets to explore, there's no end to the adventure. From the depths of space to the wildest corners of your mind, XRB Galaxy has something for everyone.</p>

                        <h2 className='text-xl lg:text-4xl tracking-wide mb-2 font-sans'>INTRODUCING</h2>
                        <h2 className='text-2xl lg:text-4xl 2xl:text-5xl font-extrabold mb-4 tracking-wide'>BITKUB METAVERSE</h2>
                        <p className='text-base 2xl:text-lg'>If you're looking for a planet to call home, look no further than Bitkub Metaverse - the first and most exciting colony in XRB Galaxy! Get ready to experience the fun and chaos of life on the frontier, where anything can happen and every day is a new adventure. Come join us in Bitkub Metaverse and be a part of the excitement!</p>
                    </div>
                    <div className='col-span-6'>
                        <img
                            className='up-and-down-2 mx-auto z-10 relative max-h-screen'
                            width={800} height={800}
                            src='/assets/images/rabbit-1.png'
                            alt='XRB GALAXY?'
                            style={{
                                maxHeight: '80vh',
                                objectFit: 'contain'
                            }}
                        />
                        <div className='absolute bottom-20 xl:top-20 -right-32'>
                            <Parallax translateY={[70, -70]} rotate={[-50, 0]}>
                                <img className='w-3/12 xl:w-10/12 left-and-right' src='/assets/images/planet-1.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>
                        <div className='absolute top-36 left-3/4'>
                            <Parallax translateY={[40, -40]} rotate={[-10, 0]}>
                                <img className='left-and-right' width={100} src='/assets/images/planet-2.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>
                    </div>
                </div>
            </div>

            <div id="slider-image" className='relative'>
                <BannerSlider />
            </div>

            <div className='relative overflow-hidden'>

                <Parallax speed={-20} translateY={[0, -10]} className='pbgx' style={{
                    backgroundImage: 'url(/assets/images/bg-2.jpg)',
                }}>
                    <ShiningStarBackground numberOfStars={2000} />
                </Parallax>


                {/*Parallax Warper*/}
                <div className='bg-black/30 z-10 relative'>

                    {/*OUR VISION*/}
                    <div id="vision" className='grid lg:grid-cols-2 items-center z-10 px-5 lg:px-32 gap-8 pt-36 relative overflow-hidden container mx-auto'>
                        <div className='pr-6'>
                            <h2 className='text-2xl lg:text-5xl font-extrabold mb-4 tracking-wide'>OUR VISION</h2>
                            <p className='lg:mb-16 text-base 2xl:text-lg'>Our vision is to be the premier destination for immersive and exciting explorations in the metaverse. We strive to be a leader in both the graphical excellence and chaotic fun of our platform, offering a truly enjoyable experience for all.</p>
                        </div>
                        <div className='lg:pr-8'>
                            <iframe className='aspect-video w-full' src="https://www.youtube.com/embed/eB55ZUks1po" title="The New Bitkub Metaverse Teaser" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>

                    <div className='z-10 relative lg:px-48 gap-8 lg:-mt-16 container mx-auto'>
                        <div className='grid grid-cols-3 gap-0 container mx-auto'>
                            <div className='lg:pl-14 z-10'>
                                <div className='relative right-0'>
                                    <div className='ring-grow-rot rounded-full'>
                                        <CircleRing />
                                    </div>
                                    <div className='absolute image-border top-0'>
                                        <img className='spect-square rounded-full' src='/assets/images/vi-1.jpg' alt='XRB GALAXY?' />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-36 pl-4 lg:pr-20'>
                                <div className='relative w-11/12'>
                                    <div className='ring-grow-rot rounded-full'>
                                        <CircleRing />
                                    </div>
                                    <div className='absolute image-border top-0'>
                                        <img className='spect-square rounded-full' src='/assets/images/vi-2.jpg' alt='XRB GALAXY?' />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-20 lg:pr-24 z-10'>
                                <div className='relative'>
                                    <div className='ring-grow-rot rounded-full'>
                                        <CircleRing />
                                    </div>
                                    <div className='absolute image-border top-0'>
                                        <img className='spect-square rounded-full' src='/assets/images/Our Vision_3_new.png' alt='XRB GALAXY?' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='absolute top-24 -left-16'>
                            <Parallax speed={-20} translateY={[170, -170]} rotate={[-10, 0]}>
                                <img className='left-and-right' width={200} src='/assets/images/planet-3.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>
                        <div className='absolute bottom-0 right-8'>
                            <Parallax speed={-20} translateY={[70, -70]} rotate={[0, -50]}>
                                <img className='left-and-right' width={150} src='/assets/images/planet-4.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>
                    </div>
                    {/*OUR VISION*/}

                    {/*WHAT TO DO IN BITKUB METAVERSE?*/}
                    <div className='z-10 relative px-5 lg:px-24 container mx-auto py-16'>
                        <h2 className='text-2xl lg:text-5xl font-extrabold mb-4 tracking-wide text-center my-8'>WHAT TO DO IN <br />BITKUB METAVERSE?</h2>
                        <div className='p-4 border-2 border-spacing-2 border-white rounded-2xl mt-8 border-cut relative'>
                            <div className='grid lg:grid-cols-2 gap-8 xl:gap-16 what-to-do p-8 lg:px-14 lg:py-8 xl:px-36 xl:py-24 rounded-2xl '>
                                {whatTodo.map((item, index) => (
                                    <div className='text-center' key={index}>
                                        <div className='mx-auto px-8 lg:px-16'>
                                            <div className='relative'>
                                                <div className='ring-grow-rot rounded-full'>
                                                    <CircleRing />
                                                </div>
                                                <div className='absolute image-border top-0'>
                                                    <img className='spect-square rounded-full' src={`/assets/images/${item.image}`} alt='XRB GALAXY?' />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className='text-xl lg:text-3xl tracking-wide mt-6 mb-6 font-sans' dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                                        <p className='text-base 2xl:text-lg text-left lg:px-6 text-justify'>{item.desc}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/*WHAT TO DO IN BITKUB METAVERSE?*/}

                    {/* PROFILE PICTURE COLLECTION */}
                    <div className='z-10 relative py-24 px-5 lg:px-16 gap-8 mt-16 '>
                        <div className='absolute top-0 left-3/4'>
                            <Parallax translateY={[40, -40]} rotate={[-10, 0]}>
                                <img className='left-and-right' width={100} src='/assets/images/planet-2.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>
                        <h2 className='text-2xl lg:text-5xl font-extrabold mb-4 tracking-wide text-center my-8'>PROFILE PICTURE <br />COLLECTION</h2>
                        <p className='lg:text-lg text-center mb-4'>Express your unique style with XRB Profile Picture collection.
                            <br className='hidden lg:block' />Choose from a variety of appearance options to stand out in the community
                        </p>
                        <div className='grid grid-cols-2 lg:grid-cols-5 gap-2 container mx-auto mt-16' style={{ transform: 'scale(0.9)' }}>
                            {profileCollection.map((item, index) => (
                                <div className='transition-all profile-warper relative mb-8' key={index}>
                                    <h3 className='text-xl xl:text-2xl tracking-wide font-bold text-center mb-2 lg:mb-6'>{item.profileName}</h3>
                                    <div className='single-profile' style={{
                                        backgroundImage: `url(/assets/images/${item.profileBg})`,
                                        backgroundPosition: 'center center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover'
                                    }}>
                                        <div className='profile-hover h-full w-full bg-black/50'>
                                            <div className='w-10/12 xl:w-7/12 mx-auto relative flex justify-center items-center' style={{ height: '200px' }}>
                                                <img className='absolute' src='/assets/images/circlePercent.svg' alt='SOLD OUT' style={{ right: '-2px' }} />
                                                <h4 className='text-center text-lg xl:text-2xl font-semibold leading-0 mb-1.5 font-sans' style={{ lineHeight: '1.1' }}>
                                                    SOLD<br />OUT
                                                </h4>
                                            </div>
                                            <img className='' src={`/assets/images/${item.profileName}.png`} alt={item.profileName} />
                                        </div>
                                    </div>
                                    <div className='profile-text z-20'>
                                        <div className="triangle"></div>
                                        <div className='chat-text-box rounded-xl p-4 xl:p-8'>
                                            <h3 className='text-xl tracking-wide font-bold mb-1 '>{item.profileName}</h3>
                                            <p className='text-sm xl:text-base'>{item.profileDesc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* PROFILE PICTURE COLLECTION */}

                    {/* CONCESSION */}
                    <div className='z-10 relative pt-24 pb-8 px-5 lg:px-16 gap-8 mt-16 '>
                        <div className='absolute top-0 left-0'>
                            <Parallax translateY={[40, -40]} rotate={[-10, 0]}>
                                <img className='left-and-right' width={150} src='/assets/images/planet-5.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>

                        <h2 className='text-2xl lg:text-5xl font-extrabold mb-4 tracking-wide text-center my-8'>CONCESSION</h2>
                        <p className='lg:text-lg text-center mb-4 mx-auto' style={{ maxWidth: '1055px' }}>
                            In Bitkub Metaverse, you can become an entrepreneur in our three-layered
                            concession system. First, buy and sell raw materials in layer one.
                            Then, use those materials to craft your own items in layer two. Finally,
                            showcase your creations in the shop in layer three.
                            Unleash your entrepreneurial spirit and turn your virtual dreams into reality!
                        </p>
                    </div>
                    <div className='concession'>
                        <ConcessionSlide items={marqueeItems} />
                    </div>
                    {/* CONCESSION */}


                    <div className='z-40 relative pt-24 px-5 lg:px-16 gap-8 mt-16 ' style={{ marginBottom: '-40vh', }}>
                        <div className='absolute top-0 right-0'>
                            <Parallax translateY={[40, -40]} rotate={[-10, 0]}>
                                <img className='left-and-right' width={150} src='/assets/images/planet-4.png' alt='XRB GALAXY?' />
                            </Parallax>
                        </div>

                        <h2 className='text-2xl lg:text-5xl font-extrabold mb-4 tracking-wide text-center my-8'>STAY IN THE LOOP!</h2>
                        <p className='lg:text-lg text-center mb-4 mx-auto' style={{ maxWidth: '1055px' }}>
                            Be the first to know about exciting updates, major reveals, <br className='hidden lg:block' />and exclusive Bitkub Metaverse content. Join our vibrant community today!
                        </p>

                        <div className="flex gap-6 justify-center items-start pt-8 w-full  lg:top-0 lg:relative z-40">
                            <a href="https://www.facebook.com/XRBGALAXY" target='_blank' rel="noreferrer" className='p-2.5 bg-white rounded-full w-20 h-20'>
                                <span className='sr-only'>Facebook</span>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 155.139 155.139" version="1.1" viewBox="0 0 155.139 155.139" xmlSpace="preserve" > <path fill="#3333cc" d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z" ></path> </svg>
                            </a>
                            <a href="https://discord.gg/7KYTzcce7K" target='_blank' rel="noreferrer" className='p-2 bg-white rounded-full w-20 h-20'>
                                <span className='sr-only'>Discord</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#3333cc" viewBox="0 0 512 512" > <path d="M133.408 346.261c95.35 37.211 176.493 44.459 268.445-13.461-.674 1.011-18.863 27.958-66.695 41.768 10.105 13.811 23.916 29.642 23.916 29.642 30.653 0 60.632-8.758 86.568-25.6 20.211-13.474 31.663-36.716 29.305-60.968-4.042-41.432-13.811-81.853-28.968-120.589-19.2-50.526-65.347-85.558-118.905-90.611-4.716-.337-8.084-.337-10.105-.337l-5.389 5.389c61.642 17.516 92.295 44.8 92.968 45.811-94.653-47.832-206.484-48.505-301.811-2.021 0 0 30.316-29.979 97.347-45.811l-4.042-4.042c-7.074 0-13.811.674-20.884 1.684-49.516 8.421-90.947 42.442-108.8 89.263-15.495 40.421-25.6 82.863-29.305 125.979-2.021 22.905 8.758 45.474 27.621 58.611 24.926 16.505 54.568 25.263 84.547 25.263 0 0 12.126-15.832 24.253-29.979-45.474-13.474-64-40.421-64.337-41.432l8.558 4.37a172.042 172.042 0 0015.713 7.071zm52.866-29.293c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-1.011 20.885-17.516 37.727-38.4 38.4zm137.431 0c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-.673 20.885-17.516 37.727-38.4 38.4z"></path> </svg>
                            </a>
                            <a href="https://t.me/+rzB_EoqTLB1kYzE1" target='_blank' rel="noreferrer" className='p-3 bg-white rounded-full w-20 h-20'>
                                <span className='sr-only'>Telegram</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#3333cc" viewBox="0 0 24 24" > <path d="M9.417 15.181l-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931L23.93 3.821l.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693L18.953 5.78c.595-.394 1.136-.176.691.218z"></path> </svg>
                            </a>
                        </div>

                    </div>

                    <div className="last-footer -top-8 -mb-8 lg:-top-0 lg:mb-0 relative px-0 " style={{
                        backgroundImage: `url(/assets/images/footer-bg.png)`,
                        backgroundPosition: 'bottom center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <div className='grid grid-cols-3 z-20 absolute bottom-16 w-full'>
                            <div className='flex justify-end'>
                                <img className='up-and-down block' width={400} src='/assets/images/rabbit-3.png' alt='XRB GALAXY?' />
                            </div>
                            <div></div>
                            <div className='flex justify-start'>
                                <img className='up-and-down block' width={400} src='/assets/images/rabbit-2.png' alt='XRB GALAXY?' />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Layout>
    );
}
export default Home;