import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {

    return (
        <div className='flex flex-col min-h-screen bg-black'>
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
