import React, { useState } from 'react';

const PDFViewer = ({ pdfURL }) => {
    return (
        <div className='pfd-frame'>
            <iframe
                src={pdfURL}
                width="100%"
                height="600"
                type="application/pdf"
                title="PDF Viewer"
                style={{ border: 'none' }}
            >
                This browser does not support PDFs. Please download the PDF to view it: <a href={pdfURL}>Download PDF</a>
            </iframe>
        </div>
    );
};

const Whitepaper = () => {
    const [pdfURL] = useState('/assets/pdf/XRB_GALAXY_Official_Whitepaper_00.6.pdf');

    return (
        <PDFViewer pdfURL={pdfURL} />
    );
}
export default Whitepaper;