import { Link } from 'react-router-dom';

const menuItems = [
    {
        menuName: 'Home',
        menuHref: '/',
        componentName: 'Home',
        openInNewTab: false,
    },
    {
        menuName: 'Roadmap',
        menuHref: '/roadmap',
        componentName: 'Roadmap',
        openInNewTab: false,
    },
    {
        menuName: 'Whitepaper',
        menuHref: '/whitepaper',
        componentName: 'Whitepaper',
        openInNewTab: true,
    },
    {
        menuName: 'Yellow Paper',
        menuHref: '/yellowpaper',
        componentName: 'YellowPaper',
        openInNewTab: true,
    }
];

const Menu = () => {
    return (
        <nav>
            <ul className='block xl:flex gap-8 uppercase relative text-lg xl:text-base leading-10'>
                {menuItems.map((item, index) => (
                    item.menuHref !== '/' &&
                    <li className='main-menu-item' key={index}>
                        <Link
                            to={item.menuHref}
                            className='transition-all font-semibold hover:font-bold hover:text-transparent bg-clip-text bg-gradient-to-r from-color1 via-color2 to-color3'
                            target={item.openInNewTab ? "_blank" : ""}
                            rel={item.openInNewTab ? "noopener noreferrer" : ""}
                        >
                            {item.menuName}
                        </Link>
                    </li>
                ))}
                <li className='main-menu-item'>
                    <span
                        //href='#'
                        //target='_blank'
                        //rel='noopener noreferrer'
                        className='transition-all font-semibold hover:font-bold hover:text-transparent bg-clip-text bg-gradient-to-r from-color1 via-color2 to-color3'
                    >
                        Reserve Land
                    </span>
                </li>
            </ul>
        </nav>
    );
};

export { menuItems };
export default Menu;