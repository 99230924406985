const Logo = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        baseProfile="tiny"
        overflow="visible"
        version="1.2"
        viewBox="0 0 428.2 149.3"
        xmlSpace="preserve"
      >
        <path
          fill="#FFF"
          d="M108.5 92.9c-14.1 0-25.5 11.6-25.5 25.8 0 14.2 11.4 25.8 25.5 25.8 7.3 0 13.8-3.1 18.5-8.1v4.3s-.1 1.1.4 1.7c.5.6 1.5.6 1.5.6h3.2v-14.7c1.2-2.9 1.8-6.2 1.8-9.5.1-14.3-11.4-25.9-25.4-25.9zm0 46.4c-11.2 0-20.3-9.2-20.3-20.6s9.1-20.6 20.3-20.6 20.3 9.2 20.3 20.6-9.1 20.6-20.3 20.6zM175.2 131.4c-3.9-4.9-3.9-12.6-3.9-12.7V96.3h-5.2v22.3c0 .4-.1 9.6 5 16 4.7 5.9 10.4 9 17.9 9.8l.5-5.2c-6-.5-10.4-2.9-14.3-7.8z"
        ></path>
        <path
          fill="#FFF"
          d="M338.5 95.5L323.2 112.8 323.2 112.8 323.2 112.8 307.8 95.5 300.9 95.5 322.3 119.6 300.9 143.7 307.8 143.7 323.2 126.4 323.2 126.4 323.2 126.4 338.5 143.7 345.5 143.7 324 119.6 345.5 95.5z"
        ></path>
        <path
          fill="#FFF"
          d="M245.9 92.9c-14.1 0-25.5 11.6-25.5 25.8 0 14.2 11.4 25.8 25.5 25.8 7.3 0 13.8-3.1 18.5-8.1v4.3s-.1 1.1.4 1.7c.5.6 1.5.6 1.5.6h3.2v-14.7c1.2-2.9 1.8-6.2 1.8-9.5.1-14.3-11.4-25.9-25.4-25.9zm0 46.4c-11.2 0-20.3-9.2-20.3-20.6s9.1-20.6 20.3-20.6 20.3 9.2 20.3 20.6-9.1 20.6-20.3 20.6zM47.1 114.6H34v3.2s0 1 .6 1.5c.4.3.9.4 1.3.4h11.6c-.6 10.8-9.6 19.5-20.5 19.5-11.4 0-20.6-9.2-20.6-20.6 0-11.3 9.1-20.5 20.4-20.6.4 0 1.1 0 1.5-.4.6-.5.6-1.5.6-1.5v-3.2h-2.1c-14.2.1-25.7 11.6-25.7 25.8 0 14.2 11.6 25.8 25.8 25.8 14.2 0 25.8-11.6 25.8-25.8 0-1.4-.1-2.8-.4-4.1h-5.2zM407.4 122l19-26.5H420l-15.2 21.2-15.2-21.2h-6.4l19 26.5v19.6c0 .3 0 1.1.4 1.6.5.6 1.5.6 1.5.6h3.2V122z"
        ></path>
        <linearGradient
          id="SVGID_1_"
          x1="-43.078"
          x2="819.948"
          y1="39.162"
          y2="39.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#70CDDD"></stop>
          <stop offset="0.681" stopColor="#3E63A5"></stop>
          <stop offset="1" stopColor="#2E3690"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M97.6 70.5s3.6 2.3 5.2 2.3h6.7s1.2 0 2.5-.9c1.3-.8 1.2-2.7 1.2-2.7s-.2-.9-1.3-2.3l-2.9-2.6-32.2-25 32.6-25.2 2.9-2.6c1.1-1.4 1.3-2.3 1.3-2.3s.1-1.8-1.2-2.7c-1.3-.8-2.5-.9-2.5-.9h-2.7c-3.3-.1-5.2.4-5.2.4l-4 1.8-30.1 23.3.1.1c-.9.6-2.7 1.5-4.3 1.5H51.4c-1.9 0-4-1.3-4.7-1.7L16.8 7.8l-4-1.8s-1.9-.5-5.2-.5H5s-1.2 0-2.5.9-1.2 2.7-1.2 2.7.2.9 1.3 2.3L5.5 14l32.6 25.2-32.3 25L3 66.9c-1.1 1.4-1.3 2.3-1.3 2.3s-.1 1.8 1.2 2.7c1.3.8 2.5.9 2.5.9h6.7c1.6 0 5.2-2.3 5.2-2.3l29.9-23.1c.7-.4 1.8-1 3-1.3h14.7c1 .2 2 .7 2.7 1.1l-.1.1 30.1 23.2z"
        ></path>
        <linearGradient
          id="SVGID_2_"
          x1="-43.078"
          x2="819.948"
          y1="39.162"
          y2="39.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#70CDDD"></stop>
          <stop offset="1" stopColor="#2E3690"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M251 70.4l1.1.9s1.5 1 4.8 1h8.3s2.7-.5 2.7-2.5c0 0 .3-1.5-.9-2.6s-5.1-4.4-5.1-4.4l-21.4-16.6h6.3c4.8 0 10.2-1.1 14.6-5s6.5-7.4 6.5-14.2-1.5-9-6-14.6-13.1-6.8-13.1-6.8H157.1c-2 0-1.9 2.5-1.9 2.5v2.5c0 2.2 0 4.3 1.7 6.4 1.7 2.1 4 2.1 4 2.1h86.8v.1c.2 0 .4-.1.6-.1 4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2c-.2 0-.4 0-.6-.1v.1h-87.1s-3.3.1-4.6 2c-1.3 1.9-1 4.6-1 4.6V70s-.1 2.8 2.1 2.8h4.3s2.3-.3 4.2-1.9c1.9-1.6 2.2-3.4 2.2-3.4V46.1H217c3.4.6 5.6 2.3 5.6 2.3l28.4 22z"
        ></path>
        <linearGradient
          id="SVGID_3_"
          x1="-43.078"
          x2="819.948"
          y1="39.162"
          y2="39.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#70CDDD"></stop>
          <stop offset="1" stopColor="#2E3690"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_3_)"
          d="M315.3 72.5h89.4c4.8 0 10.2-1.1 14.6-5 4.4-4 6.5-7.4 6.5-14.2 0-1.4-.1-2.7-.2-3.8 0-.1 0-.3-.1-.4v-.3c-1.4-9.7-10.4-14.1-10.4-14.1-.5-.3-.9-.5-1.4-.7.9-2 1.3-4.4 1.3-7.4 0-2-.1-3.5-.4-4.9 0 0-.5-3.8-4.8-9.2-4.3-5.4-11.5-6.8-13.4-6.8h-1.9l-.2.3v-.2h-79.2c-1.3 0-2.4 1.1-2.4 2.4V70c.1 1.4 1.2 2.5 2.6 2.5zm79.1-53.2s3.9-.4 6.1 1.4c1.5 1.3 2.4 3.2 2.4 5.3 0 3.9-3.1 7-7 7h-70.7V19.3h69.2zm11.7 40.5c-.2 0-.4 0-.6-.1v.1H325.1V45.4H405.5v.1c.2 0 .4-.1.6-.1 4 0 7.2 3.2 7.2 7.2s-3.2 7.2-7.2 7.2z"
        ></path>
      </svg>
    );
}
export default Logo