import { useRef, useState } from "react";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';

const VideoPlayer = ({ src }) => {

    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState(true);

    const togglePlayPause = () => {
        const video = videoRef.current;

        if (video.paused) {
            video.play();
            setIsPlaying(true);
        } else {
            video.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div className="reative">
            <video className="bannervideo magic-height" ref={videoRef} src={src} autoPlay muted loop />
            <div className='w-full absolute magic-height bg-black/30'></div>
            <div className="absolute bottom-2 left-2 z-40">
                <button className="text-xl" onClick={togglePlayPause}>
                    {isPlaying ?
                        <div className="flex items-center gap-2">
                            <div className="w-8 h-8 bg-white text-gray-800 flex justify-center items-center rounded-full">
                                <PauseOutlinedIcon sx={{ fontSize: '1.5rem' }} />
                            </div>
                            Pause
                        </div>
                        :
                        <div className="flex items-center gap-2">
                            <div className="w-8 h-8 bg-white text-gray-800 flex justify-center items-center rounded-full">
                                <PlayArrowOutlinedIcon sx={{ fontSize: '1.5rem' }} />
                            </div>
                            Play
                        </div>
                    }
                </button>
            </div>
        </div>
    );
};

export default VideoPlayer;
