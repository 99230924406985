import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { IconButton } from '@mui/material';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

const ConcessionSlide = ({ items }) => {

    return (
        <Swiper
            slidesPerView={3}

            modules={[Navigation]}
            hashNavigation={{
                watchState: true,
            }}

            navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
                disabledClass: "swiper-button-disabled"
            }}
            className="mySwiper"
            style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
            }}
            loop={true}
        >
            {items.map((item, index) => (
                <SwiperSlide key={index}>
                    <img
                        className="aspect-square object-contain object-center"
                        src={`/assets/images/concession/${item}`}
                        key={index}
                        alt="Concession"
                    />
                </SwiperSlide>
            ))}
            <div className='cc-nav'>
                <IconButton className='swiper-button image-swiper-button-prev'>
                    <KeyboardDoubleArrowLeftOutlinedIcon sx={{ color: '#fff', fontSize: '2rem' }} />
                </IconButton>
                <IconButton className='swiper-button image-swiper-button-next'>
                    <KeyboardDoubleArrowRightOutlinedIcon sx={{ color: '#fff', fontSize: '2rem' }} />
                </IconButton>
            </div>
        </Swiper>
    );

};

export default ConcessionSlide;
