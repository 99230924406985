const CircleRing = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        version="1"
        viewBox="0 0 800 800"
        xmlSpace="preserve"
      >
        <defs>
          <filter id="outerGlow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="10" />
            <feOffset dx="0" dy="0" result="offsetblur" />
            <feFlood floodColor="rgba(0, 225, 255, 0.80)" />
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g
          fill="none"
          stroke="#3CF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="5"
          filter="url(#outerGlow)"
        >
          <path d="M748.1 400c0 192.3-155.9 348.1-348.1 348.1S51.9 592.3 51.9 400 207.7 51.9 400 51.9c108.2 0 204.8 49.3 268.6 126.7M703.1 228.7c21.2 37.5 35.7 79.3 41.8 123.8M691.6 209.7c4 6.2 7.9 12.5 11.5 18.9"></path>
        </g>
      </svg>
      
    );
}

export default CircleRing;
