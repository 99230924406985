import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
const ScrollButton = ({ targetId }) => {
    const scrollDown = () => {
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <button className="scroll-button up-and-down" onClick={scrollDown}>
            <div className='font-bold -mb-1'>Scroll down</div>
            <KeyboardDoubleArrowDownOutlinedIcon sx={{ fontSize: '2.5rem' }} />
        </button>
    );
};

export default ScrollButton;