// ShiningStarBackground.js
import { useEffect, useRef } from 'react';

const ShiningStarBackground = ({ numberOfStars = 100 }) => {
    const containerRef = useRef();

    useEffect(() => {
        for (let i = 0; i < numberOfStars; i++) {
            const star = document.createElement('div');
            star.className = 'star';
            star.style.top = `${Math.random() * 100}%`;
            star.style.left = `${Math.random() * 100}%`;
            star.style.animationDelay = `${Math.random() * 2}s`;

            if (Math.random() > 0.9) {
                star.classList.add('large-star');
            }

            containerRef.current.appendChild(star);
        }
    }, [numberOfStars]);

    return <div ref={containerRef} style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }} />;
};

export default ShiningStarBackground;
