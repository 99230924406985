import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { menuItems } from './components/menuitems';
import Home from './pages/Home';
import Roadmap from './pages/Roadmap';
import Whitepaper from './pages/Whitepaper';
import YellowPaper from './pages/YellowPaper';
import { ParallaxProvider } from 'react-scroll-parallax';

const components = {
  Home,
  Roadmap,
  Whitepaper,
  YellowPaper
};

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  palette: {
    primary: {
      light: "#6e5ad5",
      main: "#3730a3",
      dark: "#000973",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <Router>
          <div className="App">
            <Routes>
              {menuItems.map((item, index) => (
                <Route
                  key={index}
                  path={item.menuHref}
                  element={React.createElement(components[item.componentName])}
                />
              ))}
            </Routes>
          </div>
        </Router>
      </ParallaxProvider>
    </ThemeProvider>
  );
}

export default App;
