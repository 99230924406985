import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";
import { IconButton } from '@mui/material';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

const BannerSlider = () => {

    const bannerArr = [
        '/assets/images/slider/HighresScreenshot00021.jpg',
        'assets/images/slider/HighresScreenshot00002.jpg',
        '/assets/images/slider/HighresScreenshot00003.jpg',
        '/assets/images/slider/HighresScreenshot00004.jpg',
        '/assets/images/slider/HighresScreenshot00007.jpg',
        '/assets/images/slider/HighresScreenshot00011.jpg',
        '/assets/images/slider/HighresScreenshot00013.jpg',
        '/assets/images/slider/HighresScreenshot00015.jpg',
        '/assets/images/slider/HighresScreenshot00019.jpg',
        '/assets/images/slider/HighresScreenshot00023.jpg',
        '/assets/images/slider/HighresScreenshot00024.jpg',
    ];

    return (
        <Swiper
            slidesPerView={1}
            autoplay={{
                delay: 2500,
                disableOnInteraction: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            hashNavigation={{
                watchState: true,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
                disabledClass: "swiper-button-disabled"
            }}
            className="mySwiper"
            style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
            }}
        >
            {bannerArr.map((banner, index) => (
                <SwiperSlide key={index}>
                    <picture alt="Banner">
                        <img src={`${banner}`} alt="Banner" className='w-full' />
                    </picture>
                </SwiperSlide>
            ))}
            <div className='cc-nav'>
                <IconButton className='swiper-button image-swiper-button-prev'>
                    <KeyboardDoubleArrowLeftOutlinedIcon sx={{ color: '#fff', fontSize: '2rem' }} />
                </IconButton>
                <IconButton className='swiper-button image-swiper-button-next'>
                    <KeyboardDoubleArrowRightOutlinedIcon sx={{ color: '#fff', fontSize: '2rem' }} />
                </IconButton>
            </div>
        </Swiper>
    );
}

export default BannerSlider;