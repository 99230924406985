import { useEffect } from 'react';
import Layout from '../layout/layout';
import ShiningStarBackground from '../components/ShiningStarBackground';
import { Parallax } from 'react-scroll-parallax';

const Roadmap = () => {

    useEffect(() => {
        document.title = 'Road Map - XRB Galaxy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <h1 className='sr-only'>Road Map</h1>

            <div className='relative overflow-hidden'>

                <Parallax speed={-20} translateY={[0, -30]} className='pbgx' style={{
                    backgroundImage: 'url(/assets/images/bg-2.jpg)',
                    backgroundPosition: '50% 15%',
                    minHeight: '400vh',
                }}>
                    <ShiningStarBackground numberOfStars={2000} />
                </Parallax>


                <div className='bg-black/30 z-10 relative'>

                    <h2 className='text-2xl lg:text-5xl font-extrabold mb-4 tracking-wide py-8 lg:py-24 text-center'>ROADMAP</h2>

                    <div className='absolute top-96 right-0 z-10'>
                        <Parallax speed={-20} translateY={[70, -70]} rotate={[250, 195]}>
                            <img className='left-and-right' width={150} src='/assets/images/planet-4.png' alt='XRB GALAXY?' />
                        </Parallax>
                    </div>

                    <div className='z-10 px-5 pl-16 lg:pl-16 lg:px-16 lg:gap-8 lg:pt-36 relative overflow-hidden container mx-auto roadmap' style={{
                        backgroundImage: 'url(/assets/images/gan.png)',
                        maxWidth: '950px',
                    }}>

                        <div className='flex flex-warp flex-col lg:flex-row items-start lg:-mt-36'>
                            <div className='w-full'>
                                <div className='border-4 rounded-lg border-color2 p-6'>
                                    <h2 className='text-xl lg:text-2xl tracking-wide font-bold'>SNEAK PEEK</h2>
                                    <h3 className='text-lg lg:text-xl tracking-wide mb-4 font-sans font-semibold'>Q3-4, 2022</h3>
                                    <ul className='list-disc list-inside'>
                                        <li>Teaser</li>
                                        <li>Exclusive Sneak Peek Event</li>
                                        <li>Character Selection</li>
                                        <li>XRB Token</li>
                                        <li>Whitepaper 0.5</li>
                                        <li>XRB Pioneer NFT - Spiritual</li>
                                        <li>XRB Pioneer NFT - Artist</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='lg:w-7/12'></div>
                            <div className='w-full'>
                                <div className='border-4 rounded-lg border-color2 p-6 mt-8 lg:mt-48 mb-8 lg:mb-52 '>
                                    <h2 className='text-xl lg:text-2xl tracking-wide font-bold'>OPEN BETA<br/>#New Planet</h2>
                                    <h3 className='text-lg lg:text-xl tracking-wide mb-4 font-sans font-semibold'>Q2, 2023</h3>
                                    <ul className='list-disc list-inside'>
                                        <li>Land Sale</li>
                                        <li>Character Customization</li>
                                        <li>Environment Interactive</li>
                                        <li>In-Game Advertisement</li>
                                        <li>Infrastructures Sale</li>
                                        <li>Chat & Emo</li>
                                        <li>Game Shop</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-warp flex-col lg:flex-row items-start lg:-mt-80'>
                            <div className='w-full'>
                                <div className='border-4 rounded-lg border-color2 p-6'>
                                    <h2 className='text-xl lg:text-2xl tracking-wide font-bold'>EP.02<br/>#Rabbitian Saga</h2>
                                    <h3 className='text-lg lg:text-xl tracking-wide mb-4 font-sans font-semibold'>Q4, 2023</h3>
                                    <ul className='list-disc list-inside'>
                                        <li>Mini-game #1</li>
                                        <li>Dungeon 100 Floor (RPG)</li>
                                        <li>Vehicle Shop</li>
                                        <li>New 2 Zones</li>
                                        <li>New Item, new farming</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='lg:w-7/12'></div>
                            <div className='w-full'>
                                <div className='border-4 rounded-lg border-color2 p-6 mt-8 lg:mt-72'>
                                    <h2 className='text-xl lg:text-2xl tracking-wide font-bold'>EP.03<br/>#???</h2>
                                    <h3 className='text-lg lg:text-xl tracking-wide mb-4 font-sans font-semibold'>Q1, 2024</h3>
                                    <ul className='list-disc list-inside'>
                                        <li>Stay tuned for updates!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='absolute bottom-96 left-0'>
                        <Parallax speed={-20} translateY={[70, -70]} rotate={[0, -50]}>
                            <img className='left-and-right' width={150} src='/assets/images/planet-4.png' alt='XRB GALAXY?' />
                        </Parallax>
                    </div>


                    <div className="relative" style={{
                        backgroundImage: `url(/assets/images/footer-bg.png)`,
                        backgroundPosition: '50% 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        height: '60vh'
                    }}>
                        <div className="flex gap-6 justify-center items-start pt-28 w-full absolute  lg:top-0 lg:relative">
                            <a href="https://www.facebook.com/XRBGALAXY" target='_blank' rel="noreferrer" className='p-2.5 bg-white rounded-full w-20 h-20'>
                                <span className='sr-only'>Facebook</span>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 155.139 155.139" version="1.1" viewBox="0 0 155.139 155.139" xmlSpace="preserve" > <path fill="#3333cc" d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z" ></path> </svg>
                            </a>
                            <a href="https://discord.gg/7KYTzcce7K" target='_blank' rel="noreferrer" className='p-2 bg-white rounded-full w-20 h-20'>
                                <span className='sr-only'>Discord</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#3333cc" viewBox="0 0 512 512" > <path d="M133.408 346.261c95.35 37.211 176.493 44.459 268.445-13.461-.674 1.011-18.863 27.958-66.695 41.768 10.105 13.811 23.916 29.642 23.916 29.642 30.653 0 60.632-8.758 86.568-25.6 20.211-13.474 31.663-36.716 29.305-60.968-4.042-41.432-13.811-81.853-28.968-120.589-19.2-50.526-65.347-85.558-118.905-90.611-4.716-.337-8.084-.337-10.105-.337l-5.389 5.389c61.642 17.516 92.295 44.8 92.968 45.811-94.653-47.832-206.484-48.505-301.811-2.021 0 0 30.316-29.979 97.347-45.811l-4.042-4.042c-7.074 0-13.811.674-20.884 1.684-49.516 8.421-90.947 42.442-108.8 89.263-15.495 40.421-25.6 82.863-29.305 125.979-2.021 22.905 8.758 45.474 27.621 58.611 24.926 16.505 54.568 25.263 84.547 25.263 0 0 12.126-15.832 24.253-29.979-45.474-13.474-64-40.421-64.337-41.432l8.558 4.37a172.042 172.042 0 0015.713 7.071zm52.866-29.293c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-1.011 20.885-17.516 37.727-38.4 38.4zm137.431 0c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-.673 20.885-17.516 37.727-38.4 38.4z"></path> </svg>
                            </a>
                            <a href="https://t.me/+rzB_EoqTLB1kYzE1" target='_blank' rel="noreferrer" className='p-3 bg-white rounded-full w-20 h-20'>
                                <span className='sr-only'>Telegram</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#3333cc" viewBox="0 0 24 24" > <path d="M9.417 15.181l-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931L23.93 3.821l.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693L18.953 5.78c.595-.394 1.136-.176.691.218z"></path> </svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </Layout>
    );
}
export default Roadmap;