import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../components/menuitems';
import Logo from '../components/Logo';
import Drawer from '@mui/material/Drawer';

const Header = () => {

    const [bgColor, setBgColor] = useState('bg-black');
    const [state, setState] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setBgColor('bg-black');
            } else {
                setBgColor('bg-black');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header className={`transition-all sticky top-0 z-50 p-4 ${bgColor}`}>
                <div className='flex justify-between items-center px-1 lg:px-4 w-full md:flex md:w-auto'>
                    <div className='w-24 lg:w-32'>
                        <Link to={`/`} >
                            <Logo />
                        </Link>
                    </div>
                    <div className='hidden xl:block'>
                        <Menu />
                    </div>
                    <div className='flex gap-3 items-center'>
                        <button className='xl:hidden hamburger-menu' onClick={() => setState(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        <button className='bg-color4 py-2 px-2 lg:px-4 rounded-lg'>Play Now</button>
                        <a href="https://www.facebook.com/XRBGALAXY" target='_blank' rel="noreferrer" className='p-2.5 bg-color4 rounded-full w-8 h-8 lg:w-10 lg:h-10'>
                            <span className='sr-only'>Facebook</span>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 155.139 155.139" version="1.1" viewBox="0 0 155.139 155.139" xmlSpace="preserve" > <path fill="#fff" d="M89.584 155.139V84.378h23.742l3.562-27.585H89.584V39.184c0-7.984 2.208-13.425 13.67-13.425l14.595-.006V1.08C115.325.752 106.661 0 96.577 0 75.52 0 61.104 12.853 61.104 36.452v20.341H37.29v27.585h23.814v70.761h28.48z" ></path> </svg>
                        </a>
                        <a href="https://discord.gg/7KYTzcce7K" target='_blank' rel="noreferrer" className='p-2 bg-color4 rounded-full w-8 h-8 lg:w-10 lg:h-10'>
                            <span className='sr-only'>Discord</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 512 512" > <path d="M133.408 346.261c95.35 37.211 176.493 44.459 268.445-13.461-.674 1.011-18.863 27.958-66.695 41.768 10.105 13.811 23.916 29.642 23.916 29.642 30.653 0 60.632-8.758 86.568-25.6 20.211-13.474 31.663-36.716 29.305-60.968-4.042-41.432-13.811-81.853-28.968-120.589-19.2-50.526-65.347-85.558-118.905-90.611-4.716-.337-8.084-.337-10.105-.337l-5.389 5.389c61.642 17.516 92.295 44.8 92.968 45.811-94.653-47.832-206.484-48.505-301.811-2.021 0 0 30.316-29.979 97.347-45.811l-4.042-4.042c-7.074 0-13.811.674-20.884 1.684-49.516 8.421-90.947 42.442-108.8 89.263-15.495 40.421-25.6 82.863-29.305 125.979-2.021 22.905 8.758 45.474 27.621 58.611 24.926 16.505 54.568 25.263 84.547 25.263 0 0 12.126-15.832 24.253-29.979-45.474-13.474-64-40.421-64.337-41.432l8.558 4.37a172.042 172.042 0 0015.713 7.071zm52.866-29.293c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-1.011 20.885-17.516 37.727-38.4 38.4zm137.431 0c-21.895-.674-39.074-19.2-38.4-41.432.674-20.884 17.516-37.726 38.4-38.4 21.895.674 39.074 19.2 38.4 41.432-.673 20.885-17.516 37.727-38.4 38.4z"></path> </svg>
                        </a>
                    </div>
                </div>
            </header >
            <Drawer
                anchor={'left'}
                open={state}
                onClose={() => setState(false)}
            >
                <div className='w-full bg-black/90 h-full text-white p-8'>
                    <Menu />
                </div>

            </Drawer>
        </>
    );
}

export default Header;